<template>
    <div>
        <transition name="apop">
            <div class="poppup-box" v-if="popVisible">
                <div class="pop-title a-flex-rcc a-mt-16">
                    <span class="a-fs-14 a-c-white">{{ title }}</span>
                </div>
                <div class="pop-subTitle a-flex-rcc a-mt-16" v-if="subTitle">
                    <span class="a-fs-12 a-c-white">{{ subTitle }}</span>
                </div>
                <!-- 弹窗内容 -->
                <slot></slot> 
                <div class="line"></div>
                <div class="pop-footer a-c-white a-fs-14 a-flex-rfsc">
                    <div class="pop-confirm-btn a-flex-rcc a-flex-1" v-if="showConfirm" @click="confirmFun">{{ confirmText }}</div>
                    <div class="btn-line" v-if="showConfirm && showCancel"></div>
                    <div class="pop-confirm-btn a-flex-rcc a-flex-1" v-if="showCancel" @click="cancelFun">{{ cancelText }}</div>
                </div>
            </div>
        </transition>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                popVisible: false,// 是否显示弹窗
                title: '',
                subTitle: '',
                showConfirm: true,
                showCancel: true,
                confirmText: '',
                cancelText: '',
                callback: null,//回调函数
            }
        },
        methods:{
            confirmFun () {
                this.close()
                if(typeof(this.callback) == 'function') this.callback('confirm')
            },
            cancelFun () {
                this.close()
                if(typeof(this.callback) == 'function') this.callback('cancel')
            },
            close () {
                this.popVisible = false
            },
            open (popObj,callback) {
                this.popVisible = true
                this.title = popObj.title ? popObj.title : '温馨提示'
                this.subTitle = popObj.subTitle ? popObj.subTitle : ''
                this.showConfirm = popObj.showConfirm ? true : false
                this.showCancel = popObj.showCancel ? true : false
                this.confirmText = popObj.confirmText ? popObj.confirmText : '确认'
                this.cancelText = popObj.cancelText ? popObj.cancelText : '取消'
                this.callback = callback
            }
        }
    }
</script>

<style scoped>
    .poppup-box{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 246px;
        margin-left: -123px;
        margin-top: -77px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 8px;
        z-index: 9999;
    }
    .pop-subTitle{
        padding: 0 20px;
        text-align: center;
    }
    .line{
        width: 100%;
        height: 1px;
        background: #909499;
    }
    .pop-footer{
        height: 38px;
    }
    .btn-line{
        width: 1px;
        height: 100%;
        background: #909499;
    }
    /* 动画 */
    .apop-enter-active, .apop-leave-active {
        transition: all .2s;
    }
    .apop-enter, .apop-leave-to{
        opacity: 0;
    }
</style>