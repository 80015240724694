<template>
    <div class="staffeBox">
        <le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <template slot="headerLabel" slot-scope="scope">
               <div class="font16 fnW600 mgb20">共{{scope.val}}名员工</div>
            </template>
            <el-table :data="tableData" :highlight-current-row="true" max-height="480" style="width: 100%">
                <el-table-column prop="userName" label="姓名" min-width="100"></el-table-column>
                <el-table-column prop="mobile" label="联系电话" min-width="100"></el-table-column>
                <el-table-column label="创建时间"  width="200">
                    <template slot-scope="{ row }">
                        <span>{{ row.createTimeText || "" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="操作" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text"  @click="toFranchiseeDetail(scope.row)">查看权限</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footerLabel" slot-scope="scope">
                <div class="a-flex-rfsc"></div>
            </template>
        </le-pagview>
    </div>
</template>

<script>
    export default {
        props:['companyId'],
        data() {
            return {
               	pageParam: null,
                tableData: [],//数据列表
            }
        },
        watch:{
            companyId: {
                immediate: true,
                handler (nval) {
                    if(nval){
                        this.pageParam = {
                            url: this.$Config.apiUrl.getCompanyUser,
                            method: "post",
                            params: {
                                searchKey: "",//加盟商名称
                                companyId: nval,//所属商户
                            },
                            freshCtrl: 1,
                        }
                    }
                }
            }
        },
        methods:{
            setTableData(data){
                this.tableData = data;
            },
             //跳转详情
            toFranchiseeDetail(datas) {
                this.$router.push({
                    path: `/staffManage/staff-detail?id=${datas.id}&userId=${datas.userId}&companyId=${datas.companyId}`
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .staffeBox{
        &>.a-mt-16{
            margin-top: 0;
        }
    }
</style>