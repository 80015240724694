<template>
    <div >
        <div class="deviceBox">
            <le-input-icon-search v-model="pageParam.params.searchKey" @input="searchFun"  placeholder="搜索设备号/ICCID" />
        </div>     
        <div class="bgC1 w100p bodr4">
            <div class="flex_between a-pb-10">
                <div class="font16 fnW600">{{total}}台设备</div>
                <el-dropdown placement="bottom-start" @command="handlerOperationDev">
                    <el-button>批量操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in optionsBatch" :command='item.value + "#"' :key="item.value"
                            :value="item.value">{{ item.label }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <le-pagview @setData="setTableData" :pageParam="pageParam" :total.sync="total">
                <el-table ref="deviceList" 
                    :data="tableData" 
                    :row-key="getRowKey"
                    @selection-change="handleSelectionChange"
                    :highlight-current-row="true" 
                    style="width: 100%" >
                    <el-table-column type="selection" width="55" align="center" fixed="left"></el-table-column>
                    <el-table-column prop="deviceCode" label="设备号" min-width="100"></el-table-column>
                    <el-table-column prop="stationName" label="所属站点" min-width="100"></el-table-column>
                    <el-table-column prop="companyName" label="设备状态" min-width="100">
                        <template slot-scope="{ row }">
                                <span>{{ row.onlineStatus | initDic(optionsDeviceStatus) }}</span>
                            </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="插座信号" min-width="100">
                        <template slot-scope="{ row }">
                            <div>
                                <span class="a-fs-14 c29CC29" v-if="row.csq && row.csq >= 18">强(-1db)</span>
                                <span class="a-fs-14 cFFA900" v-else-if="row.csq && row.csq >= 11 && row.csq < 18">中(-50db)</span>
                                <span class="a-fs-14 cFF3B30" v-else-if="row.csq && row.csq < 11 ">弱(-98db)</span>
                                <span class="a-fs-14 a-c-normal" v-else>无信号</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="启用/禁用">
                        <template slot-scope="scope">
                            <el-switch
                                    @change="devUseOrNotUse(scope.row,scope.$index)"
                                    v-model="scope.row.canUse"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#007AFF"
                                    inactive-color="#F56C6C">
                                </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="180" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="@/assets/icon/option-detail.png" class="a-wh-16"
                                    @click="getDeviceInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item mglr16" effect="dark" content="二维码" placement="top">
                                <img src="@/assets/icon/qrcode.png" class="a-wh-16"
                                @click="qrVal = scope.row.qrcodeUrl" />
                            </el-tooltip>
                        <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperationDev">
                                    <img src="@/assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="item in optionsMoreOperation" :command='item.value+"#"+scope.$index' :key="item.value" :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope">
                    <div class="a-flex-rfsc"></div>
                </template>
            </le-pagview>
            <dev-param-set ref="paramSet" @paramsSet="paramsSetResult"></dev-param-set>
            <!-- <dev-param-result ref="paramResult" @resetParams="resetParams"></dev-param-result> -->
            <dev-reboot-result ref="rebootResult" :tableData="rebootResult"></dev-reboot-result>
            <a-poppup ref="aPoppup">
                <div class="pop-content a-flex-rcc">
                    <!-- <el-progress class="progress" :stroke-width="8" :percentage="percentage" color="#007AFF" :show-text="false"></el-progress> -->
                    <div class="progress">
                        <div class="progress-item"></div>
                    </div>
                </div>
            </a-poppup>
        </div>
    </div>
</template>

<script>
import DevQrcode from './deviceChild/dev-qrcode.vue';
import DevParamSet from './deviceChild/dev-paramSet.vue';
import DevParamResult from './deviceChild/dev-param-result.vue';
import APoppup from '../../components/poppup/a-poppup.vue'
import DevRebootResult from './deviceChild/dev-reboot-result.vue';
export default {
    components:{  
        DevQrcode,
        DevParamSet,
        DevParamResult,
        APoppup,
        DevRebootResult
    },
    props: ['names','companyId'],
    filters:{
        initDic (value,dic) {
            if(value === '' || value === undefined || value === null){
                return ''
            }else{
                for(var i=0; i<dic.length;i++){
                    if(value==dic[i].value){
                        return dic[i].label
                    }
                }
            }
        },
    },
    data() {
        return {
            optionsBatch: [
                { label: "解绑", value: 1 },
                { label: "重启", value: 2 },
                { label: "启用", value: 3 },
                { label: "禁用", value: 4 },
                { label: "参数设置", value: 5 }
            ],
            optionsMoreOperation: [
                { label: "解绑", value: 1 },
                { label: "重启", value: 2 },
                { label: "参数设置", value: 5 }
            ],
            optionsDeviceStatus: [
                { label: "离线", value: 0 },
                { label: "在线", value: 1 },
                { label: "故障", value: 2 }
            ],
            pageParam: {
                url: this.$Config.apiUrl.getDeviceList,
                method: "post",
                params: {
                    searchKey: "",//加盟商名称
                    companyId: this.companyId
                },
                freshCtrl: 1,
            },
            optionsProject: [],//加盟商字典
            tableData: [],//数据列表
            selectData: [],//选中的列表
            rebootResult: [],//重启结果
            pagenum:1,
            qrVal: '',
            total:0
        }
    },
    watch: {
        names(nval) {
            if (nval == 'dfxx') {
                this.pageParam.freshCtrl+=1
            }
        }
    },
    methods: {
        getRowKey (row) {
            return row.deviceCode
        },
        paramsSetResult (datas) {
            this.$refs.paramResult.tableData = datas
            this.$refs.paramResult.dialogVisible = true
        },
        // 远程重启
        batchRebootDev (datas) {
            this.showPop()
            this.$Axios._post({
                url: this.$Config.apiUrl.batchReboot,
                method: "post",
                noShowLoading: true,
                params: {
                    deviceCodes : datas
                }
            }).then(res => {
                this.$refs['aPoppup'].close()
                if(res.result.code == 0){
                    this.rebootResult = res.result.data
                    this.$refs['rebootResult'].dialogVisible = true
                    this.pageParam.freshCtrl++; // 重新请求表格数据
                }else{
                    this.$message.error(res.result.message)
                }
            }).catch(()=>{
                this.$refs['aPoppup'].close()
            })
        },
        setTableData(data,pagenum) {
            this.tableData=data
            this.pagenum = pagenum
            let defaultArr = []
            if(this.selectData[pagenum-1]){
                this.tableData.map(item=>{
                    this.selectData[pagenum-1].map(ite=>{
                        if(item.deviceId==ite.deviceId){
                            defaultArr.push(item)
                        }
                    })
                })
                this.toggleSelection(defaultArr)
            }
        },
        //启用 | 禁用
        devUseOrNotUse (datas,index) {
            if(datas.canUse == 0){
                this.handlerOperationDev('4#'+index)
            }else if(datas.canUse == 1) {
                this.handlerOperationDev('3#'+index)
            }
        },
          // 批量操作表格
        handlerOperationDev (commands) {
            let command = commands.split('#')[0]
            let index = commands.split('#')[1]
            let arr = this.selectData.length ? this.selectData.reduce((a,b)=>{ a.concat(b) }) : []
            if(index) {
                // 如果有index值,则是表格单独操作, 否则为批量操作
                arr = [this.tableData[index]]
            }
            if(!arr.length) {
                this.$message.warning('请至少选择一台设备')
                return
            }
            switch (command) {
                case '1':
                    // 解绑
                    this.$confirm('是否确认将所选设备解除绑定?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        let reqData = []
                        arr.map(item=>{
                            reqData.push(item.deviceCode)
                        })
                        this.unbindDev(reqData)
                    }).catch(_=>{})
                    break;
                case '2':
                    // 重启
                    this.$confirm('是否确认远程重启所选设备?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        let reqData = []
                        arr.map(item=>{
                            reqData.push(item.deviceCode)
                        })
                        this.batchRebootDev(reqData)
                    }).catch(_=>{ })
                    break;
                case '3':
                    // 启用
                    this.$confirm('是否确认启用所选设备?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        let reqData = []
                        arr.map(item=>{
                            reqData.push(item.deviceCode)
                        })
                        this.setCanUse(reqData)
                    }).catch(_=>{
                        this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0
                    })
                    break;
                case '4':
                    // 禁用
                    this.$confirm('是否确认禁用所选设备?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        let reqData = []
                        arr.map(item=>{
                            reqData.push(item.deviceCode)
                        })
                        this.setNotUse(reqData)
                    }).catch(_=>{
                        this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0
                    })
                    break;
                case '5':
                    // 参数下发
                    this.devParamSet(arr)
                    break;
                case '6':
                    // 设备编辑
                    this.$router.push({
                        path:'/device/device-edit',
                        query: {
                            deviceCode: arr[0].deviceCode
                        }
                    })
                    break;
                default:
                    break;
            }
        },
         // 选中or取消选中
        toggleSelection(rows) {
            this.$nextTick(() => {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.deviceList.toggleRowSelection(row);
                    });
                }else{
                    this.$refs.deviceList.clearSelection();
                }
            })            
        },
         // 表格多选
        handleSelectionChange(datas) {
            this.selectData[this.pagenum-1] = datas
        },
         // 解绑设备
        unbindDev (datas) {
            this.$Axios._post({
                url: this.$Config.apiUrl.batchUnBindDevice,
                method: "post",
                params: {
                    deviceCodes : datas
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++; // 重新请求表格数据
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        // 启用设备
        setCanUse (datas) {
            this.$Axios._post({
                url: this.$Config.apiUrl.batchSetDeviceCanUse,
                method: "post",
                params: {
                    deviceCodes : datas
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++; // 重新请求表格数据
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        //批量禁用设备
        setNotUse (datas) {
            this.$Axios._post({
                url: this.$Config.apiUrl.batchSetDeviceStopUse,
                method: "post",
                params: {
                    deviceCodes : datas
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++; // 重新请求表格数据
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        // 跳转设备详情
        getDeviceInfo (datas) {
            this.$router.push({
                path: '/device/device-info',
                query:{
                    deviceCode: datas.deviceCode
                }
            })
        },
         // 参数下发
        devParamSet (datas,type='set') {
            this.$refs.paramSet.devDatas = datas
            this.$refs.paramSet.setType = type
            this.$refs.paramSet.deviceId = type == 'single'?datas[0].deviceId:''
            this.$refs.paramSet.dialogVisible = true
        },
        searchFun(){
            this.pageParam.freshCtrl++
        }
    }
}
</script>

<style lang="scss" scoped>
    .c29CC29{
        color: #29CC29;
    }
    .cFFA900{
        color: #FFA900
    }
    .cFF3B30{
        color: #FF3B30
    }
    .deviceBox{
        /deep/ .a-ml-20{
            margin: 0;
        }
    }
</style>