<template>
    <div class="bank-account-content a-flex-rfsc a-flex-wrap">
        <div class="bank-info mgr11" v-for="(item,index) in bankList" :key="index">
            <div class="bank-info-head a-flex-rfsc">
                <img src="../../../assets/icon/card-icon.png" style="width:14px;height:14px;margin-right:6px" alt="">
                <el-tooltip effect="dark" :content="item.bankBranch" placement="top">
                    <span class="a-fs-14 a-c-normal bankName">{{ item.bankBranch }}</span>
                </el-tooltip>
            </div>
            <div class="a-fs-20 a-mlr-17 pdtb11 a-fw-700">{{ item.accountNumber | initBankNumber }}</div>
            <div class="a-fs-12 a-mlr-17 a-c-second">{{ item.accountName }}</div>
            <div class="a-flex-rfec a-mlr-17" style="padding: 6px 0 8px">
                <div class="a-flex-rfsc">
                    <div class="a-flex-rcc bank-btn1" @click="toCheck(item)" v-if="item.authStatus==1 || item.authStatus==0 || item.authStatus==3">
                        <span>去认证</span>
                    </div>
                    <div class="a-flex-rcc bank-btn2" v-if="item.authStatus==2">
                        <span>已认证</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!bankList.length">
            <el-empty description="暂无记录"></el-empty>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                bankList: []
            }
        },
        props: ['companyId'],
        filters:{
            initBankNumber (val) {
                return val.replace(/\s/g,'').replace(/(.{4})/g,"$1 ");
            }
        },
        mounted () {
            this.getBankAccountList()
        },
        methods: {
            // 获取银行账户列表
            getBankAccountList () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getBankAccountList,
                    method: "get",
                    params: {
                        companyId: this.companyId
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.bankList = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            toCheck (datas) {
                this.$confirm('是否确认通过该银行卡认证？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.authBank,
                        method: "post",
                        params: {
                            id: datas.id
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            this.$message.success('操作成功')
                            this.getBankAccountList()
                        }else{
                            this.$message.error(res.result.message)
                        }
                    })
                })
                .catch(_=>{})
            }
        },
}
</script>

<style lang="scss" scoped>
.bank-account-content{
    background: #FFFFFF;
    border-radius: 3px;
    margin-top: 11px;
    .bankName{
        width: 440px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}
.bank-info{
    background: #ffffff;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #EBF0F5;
    width: 515px;
    margin-bottom: 11px
}
.bank-info-head{
    background: #F2F8FF;
    border-radius: 3px 3px 0px 0px;
    padding: 9px 17px
}
.pdtb11{
    padding: 11px 0
}
.ffa900{
    color: #FFA900
}
.bank-btn1{
    width: 60px;
    height: 32px;
    padding: 4px 8px;
    background: #D9EBFF;
    border-radius: 16px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #007AFF;
    cursor: pointer;
}
.bank-btn2{
    width: 60px;
    height: 32px;
    padding: 4px 8px;
    border: 1px solid #67C23A;
    border-radius: 16px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #67C23A;
    cursor: pointer;
}
.add-bank-content{
    height: 158px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px dashed #DADDE0;
    cursor: pointer;
    width: 515px;
    margin-bottom: 11px
}
.mgr11{
    margin-right: 11px
}

</style>