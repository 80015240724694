<template>
    <div>
        <le-pagview @setData="setTableData" :pageParam="pageParam">
            <el-table :data="tableData" :highlight-current-row="true" >
                <el-table-column prop="name" label="站点名称" min-width="180"></el-table-column>
                <el-table-column prop="address" label="站点地址" min-width="180"></el-table-column>
                <el-table-column prop="hisOrderAmount" label="历史营业额(元)" min-width="80">
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(row.hisOrderAmount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="todayOrderAmount" label="今日营业额(元)" min-width="80">
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(row.todayOrderAmount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="todayOrderNum" label="今日订单数" min-width="80"></el-table-column>
                <el-table-column prop="onlineDeviceNum" label="在线/总数(台)" min-width="80"></el-table-column>
                <el-table-column prop="usingDeviceNum" label="正在充电人数" min-width="80"></el-table-column>
                <el-table-column prop="name" label="操作" width="80" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="详情" placement="top">
                            <img src="@/assets/icon/option-detail.png" class="a-wh-16"
                                @click="toFranchiseeDetail(scope.row)" />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footerLabel" slot-scope="scope">
                <div class="a-flex-rfsc"></div>
            </template>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
		props:{
			companyId:{
				type:Number
			},
			isSearchAgency:{
				type:Number,
				default:0 //是否查询代理站点 0:不查询 1:只查询代理站点 2:自营代理全查, 4:查询分成站点
			}
		},
        data() {
            return {
                util: util,
               	pageParam: null,
                optionsProject: [],//加盟商字典
                tableData: [],//数据列表
            }
        },
        watch:{
            companyId:{
				handler(val){
					if(val){
					    this.pageParam={
					        url: this.$Config.apiUrl.getStationInfoList,
					        method: "post",
					        params: {
					            isSearchAgency:this.isSearchAgency,
								searchCompanyId:this.isSearchAgency != 4 ? this.companyId : '',
                                agencyCompanyId:this.isSearchAgency == 4 ? this.companyId : '',
					        },
					        freshCtrl: 1,
					    }
					}
				},
				immediate:true
            },
			isSearchAgency:{
			    handler(val){
			    	if(val){
			    	    this.pageParam={
			    	        url: this.$Config.apiUrl.getStationInfoList,
			    	        method: "post",
			    	        params: {
			    	            isSearchAgency:this.isSearchAgency,
			    				searchCompanyId:this.isSearchAgency != 4 ? this.companyId : '',
                                agencyCompanyId:this.isSearchAgency == 4 ? this.companyId : '',
			    	        },
			    	        freshCtrl: 1,
			    	    }
			    	}
			    },
			    immediate:true
			}
        },
        methods:{
            setTableData(data){
                this.tableData=data
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>