<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="750px">
            <div class="title" slot="title">设备远程重启结果</div>
            <el-table :data="tableData">
                <el-table-column label="设备号" prop="deviceCode" min-width="128"></el-table-column>
                <el-table-column label="重启结果" min-width="200">
                    <template slot-scope="{ row }">
                        <span v-if="row.response.msg=='success'">成功</span>
                        <span v-else-if="row.response.msg=='timeout'">超时</span>
                        <span v-else-if="row.response.msg=='fail'">失败</span>
                        <span v-else>{{ row.response.msg?row.response.msg:'— —' }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button type="primary" @click="dialogVisible = false">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                dialogVisible: false,
            }
        },
        props: {
            tableData:{
                type: Array,
                default: []
            }
        },
    }
</script>

<style lang="scss" scoped>
.title{
    font-size: 16px;
}
.mgb28{
    margin-bottom: 28px;
}
</style>