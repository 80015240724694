<template>
    <div>
        <el-form ref="form" class="a-mt-24" :model="form" :rules="rules" :inline="true" label-width="112px" label-position="left">
            <el-form-item label="接入负载电流阈值" prop="checkPluginAmpereMin" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkPluginAmpereMin" 
                    @input="form.checkPluginAmpereMin=form.checkPluginAmpereMin.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入" >
                    <span slot="suffix" class="cy_suffix">mA</span>
                </el-input>
            </el-form-item>
            <el-form-item label="接入负载检测时长" prop="checkPluginTime" >
                <el-input style="width:370px;" v-model="form.checkPluginTime" type="number" placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">秒</span>
                </el-input>
            </el-form-item>
            <el-form-item label="开始充电后多长时间检测拔出" prop="checkPlugoutTime" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkPlugoutTime" 
                    @input="form.checkPlugoutTime=form.checkPlugoutTime.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">秒</span>
                </el-input>
            </el-form-item>
            <el-form-item label="检测时长" prop="checkTime" >
                <el-input style="width:370px;" v-model="form.checkTime" type="number" placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">秒</span>
                </el-input>
            </el-form-item>
            <el-form-item label="充满下限" prop="checkFullMinAmpere" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkFullMinAmpere" 
                    @input="form.checkFullMinAmpere=form.checkFullMinAmpere.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">mA</span>
                </el-input>
            </el-form-item>
            <el-form-item label="充满上限" prop="checkFullMaxAmpere" >
                <el-input 
                    style="width:370px;" 
                    v-model="form.checkFullMaxAmpere" 
                    @input="form.checkFullMaxAmpere=form.checkFullMaxAmpere.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">mA</span>
                </el-input>
            </el-form-item>
            <el-form-item label="充满检测时长" prop="checkFullTime" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkFullTime" 
                    @input="form.checkFullTime=form.checkFullTime.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">秒</span>
                </el-input>
            </el-form-item>
            <el-form-item label="浮充下限" prop="checkFloatMinAmpere" >
                <el-input 
                    style="width:370px;" 
                    v-model="form.checkFloatMinAmpere" 
                    @input="form.checkFloatMinAmpere=form.checkFloatMinAmpere.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">mA</span>
                </el-input>
            </el-form-item>
            <el-form-item label="浮充上限" prop="checkFloatMaxAmpere" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkFloatMaxAmpere" 
                    @input="form.checkFloatMaxAmpere=form.checkFloatMaxAmpere.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">mA</span>
                </el-input>
            </el-form-item>
            <el-form-item label="浮充检测时长" prop="checkFloatTime" >
                <el-input 
                    style="width:370px;" 
                    v-model="form.checkFloatTime" 
                    @input="form.checkFloatTime=form.checkFloatTime.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">小时</span>
                </el-input>
            </el-form-item>
            <el-form-item label="过载电流" prop="limitAmpere" >
                <el-input style="width:370px;margin-right: 32px;" type="number" v-model="form.limitAmpere" placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">A</span>
                </el-input>
            </el-form-item>
            <el-form-item label="短路电流" prop="shortAmpere" >
                <el-input style="width:370px;" v-model="form.shortAmpere" type="number" placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">A</span>
                </el-input>
            </el-form-item>
            <el-form-item label="功率检测时长" prop="checkanergytime" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.checkanergytime" 
                    @input="form.checkanergytime=form.checkanergytime.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">秒</span>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                form:{
                    checkPluginAmpereMin: '',
                    checkPluginTime: '',
                    checkPlugoutTime: '',
                    checkTime: '',
                    checkFullMinAmpere: '',
                    checkFullMaxAmpere: '',
                    checkFullTime: '',
                    checkFloatMinAmpere: '',
                    checkFloatMaxAmpere: '',
                    checkFloatTime: '',
                    limitAmpere: '',
                    shortAmpere: '',
                    checkanergytime: '',
                },
                rules:{
                    checkPluginAmpereMin: [{required: true, message:'请输入接入负载电流阈值', trigger: 'blur'}],
                    checkPluginTime: [{required: true, message:'请输入接入负载检测时长', trigger: 'blur'}],
                    checkPlugoutTime: [{required: true, message:'请输入开始充电后多长时间检测拔出', trigger: 'blur'}],
                    checkTime: [{required: true, message:'请输入检测时长', trigger: 'blur'}],
                    checkFullMinAmpere: [{required: true, message:'请输入充满下限', trigger: 'blur'}],
                    checkFullMaxAmpere: [{required: true, message:'请输入充满上限', trigger: 'blur'}],
                    checkFullTime: [{required: true, message:'请输入充满检测时长', trigger: 'blur'}],
                    checkFloatMinAmpere: [{required: true, message:'请输入浮充下限', trigger: 'blur'}],
                    checkFloatMaxAmpere: [{required: true, message:'请输入浮充上限', trigger: 'blur'}],
                    checkFloatTime: [{required: true, message:'请输入浮充检测时长', trigger: 'blur'}],
                    limitAmpere: [{required: true, message:'请输入过载电流', trigger: 'blur'}],
                    shortAmpere: [{required: true, message:'请输入短路电流', trigger: 'blur'}],
                    checkanergytime: [{required: true, message:'请输入功率检测时长', trigger: 'blur'}],
                }
            }
        },
        props:['setType','deviceId'],
        watch:{
            form (val) {
                this.$emit('input',val)
            },
            setType:{
                immediate:true,
                handler (val) {
                    if(val == 'set'){
                        this.getDefaultParams()
                    }else if(val == 'reset'){
                        this.form = JSON.parse(window.localStorage.getItem('devparams2'))
                    }else if(val == 'single'){
                        this.getParams()
                    }
                }
            }
        },
        mounted () {
            
        },
        methods:{
            getDefaultParams () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getJdDeviceDefaultParam,
                    method: "get",
                    params: {}
                }).then(res => {
                    if(res.result.code == 0){
                        this.form = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            getParams () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getJdDeviceParam,
                    method: "get",
                    params: {
                        deviceId: this.deviceId
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.form = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            check () {
                return new Promise ((resolve, reject)=>{
                    this.$refs['form'].validate((valid) => {
                        if (valid) {
                            resolve(true)
                        }else{
                            reject('二路')
                        }
                    })
                })
                
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    align-items: flex-start !important;
    line-height: 22px;
}
</style>