<template>
    <div class="changeTime a-flex-rfsc">
        <div class="a-flex-rfsfs changeTime-tabs a-fs-14">
            <span @click="handlerchange(index)" :class="{ 'active': activeTime == index }"
                v-for="(item, index) in timeType" :key="item">{{ item }}</span>

        </div>
        <div class="a-ml-45 a-pos-r">
            <el-date-picker v-model="timerange" :type="changeType[activeTime]"
                :format="activeTime == 0 ? 'yyyy 第 WW 周' : ''" :placeholder="'选择' + timeType[activeTime]"
                popper-class="changeTimePopper" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                :picker-options="activeTime == 3 ? pickerOptions : pickerNormalOptions" @change="getTimeValue"
                :append-to-body="false" :editable="false" :key="activeTime">
            </el-date-picker>


        </div>

    </div>
</template>
<script>
import util from '../../../utils/util.js'

export default {
    props: ['activeT', 'startTime', 'endTime'],
    data() {
        return {
            util: util,
            activeTime: this.activeT||1,
            timerange: new Date(),
            timeValue: [],
            start: this.startTime,
            end: this.endTime,
            timeType: ['周', '月', '年', '时间段'],
            changeType: ['week', 'month', 'year', 'daterange'],
            pickerNormalOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick: picker => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);

                    }
                }, {
                    text: '最近一个月',
                    onClick: picker => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);

                    }
                }, {
                    text: '最近三个月',
                    onClick: picker => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);


                    }
                }],
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                },
            }
        }
    },
    watch: {
        timerange(val) {
            if (val) {

                this.timerange = this.$set(this, 'timerange', val);

                if (Array.isArray(val) && val.length > 1) {
                    this.$emit('update:startTime', util.easyformatDate(val[0]))
                    this.$emit('update:endTime', util.easyformatDate(val[1]))
                    this.end = util.easyformatDate(val[1])
                    this.start = util.easyformatDate(val[0])

                }
            }

        }
    },
    mounted() {
        this.getTimeValue(new Date())
    },
    methods: {
        handlerchange(index) {
            let that = this
            this.activeTime = index
            this.timerange = new Date()
            this.$emit('update:activeT', index)
			this.$emit('update:timeType', this.timeType[index])

            this.getTimeValue(new Date())
            if (index == 3) {
                let a = new Date(new Date().getTime() - 24 * 30 * 60 * 60 * 1000)
                this.timerange = [a, new Date()]
            }

        },
        getTimeValue(time) {
            // console.log(time)
            if (this.activeTime == 3) return false

            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            if (this.activeTime == 0) {
                this.start = util.easyformatDate(time.getTime() - 24 * 60 * 60 * 1000)
                this.end = util.easyformatDate(time.getTime() + 24 * 5 * 60 * 60 * 1000)
            }
            if (this.activeTime == 1) {
                this.start = util.easyformatDate(new Date(y, m, 1))
                if (m < new Date().getMonth()) {
                    this.end = util.easyformatDate(new Date(y, m + 1, 0));
                } else {
                    this.end = util.easyformatDate(new Date())
                }

            }
            if (this.activeTime == 2) {
                this.start = y + '-01-01'
                if (y < new Date().getFullYear()) {
                    this.end = util.easyformatDate(new Date(y, 12, 0));
                } else {
                    this.end = util.easyformatDate(new Date())
                }
            }
            this.$emit('update:startTime', this.start)
            this.$emit('update:endTime', this.end)

        }
    }
}
</script>
<style  lang="scss" scoped>
.changeTime {
    position: relative;

    &-tabs {
        span {
            border: 1px #DADDE0 solid;
            border-left: 1px #fff solid;
            background: #fff;
            height: 32px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 64px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: #f9f9f9;
            }

            &:nth-of-type(1) {
                border-radius: 4px 0 0 4px;
                border-left: 1px #DADDE0 solid;
            }

            &:nth-last-of-type(1) {
                border-radius: 0 4px 4px 0;
            }

            &.active {
                border: 1px solid #007AFF;
                color: #007AFF;
            }
        }
    }


}

.changeTimePopper {
    position: absolute;
    z-index: 1000;
}

/deep/ .el-date-editor {
    position: relative;
}

/deep/ .el-picker-panel {
    position: absolute !important;
    top: 32px !important;
    left: 0 !important;
    z-index: 10000 !important;
}
</style>