<template>
    <div>
        <el-dialog title="查看设备二维码" center width="400px" :visible.sync="dialogVisible">
            <div class="a-flex-rcc a-mtb-20">
                <div class="dev-qrcode" id="dev-qrcode"></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false
        }
    },
    props: ['qrVal'],
    watch: {
        qrVal: {
            immediate: true,
            handler(val) {
                if (!val) return
                this.dialogVisible = true
                this.$nextTick(() => {
                    jQuery('#dev-qrcode').html('')
                    jQuery('#dev-qrcode').qrcode({
                        render: "canvas",
                        text: val,
                        width: 148,
                        height: 148,
                        background: "#ffffff",//背景颜色  
                        foreground: "#000000", //前景颜色
                    });
                })

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dev-qrcode {
    width: 148px;
    height: 148px;
}
</style>