<template>
    <div>
        <le-pagview @setData="setTableData" :pageParam="pageParam">
            <el-table :data="tableData" :highlight-current-row="true" max-height="480" style="width: 100%">
             <el-table-column label="变更时间" align="center" width="200">
                    <template slot-scope="{ row }">
                        <span>{{ row.opTimeStr || "" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="变更内容" min-width="200"></el-table-column>
                <el-table-column prop="toStateText" label="当前状态" min-width="100"></el-table-column>
                <el-table-column prop="opUserRealName" label="操作人" min-width="100"></el-table-column>
            </el-table>
            <template slot="footerLabel" >
                <div class="a-flex-rfsc"></div>
            </template>
        </le-pagview>
    </div>
</template>

<script>
    export default {
        props:['names','agencyId'],
        data() {
            return {
               	pageParam: {
                    url:this.$Config.apiUrl.getAgencyTimeLine ,
                    method: "get",
                    params: {
                        agencyId: this.agencyId,//加盟商名称
                    },
                    freshCtrl: 1,
                },
                tableData: [],//数据列表
            }
        },
        watch: {
            names(nval) {
                if (nval == 'htbgjl') {
                    this.pageParam.freshCtrl++
                }
            }
        },
        methods:{
            setTableData(data){
                this.tableData=data
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>