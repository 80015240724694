<template>
    <div>
        <el-dialog
            title="设置客诉通知人"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="id" label="选择员工">
                        <el-select
                            style="width: 400px;margin-right: 12px"
                            v-model="form.id"
                            filterable
                            remote
                            multiple
                            reserve-keyword
                            placeholder="请选择员工（可搜索手机号）"
                            :remote-method="remoteMethod">
                            <el-option
                                v-for="item in staffList"
                                :key="item.value"
                                :label="item.userName + ' ' + item.mobile"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-button icon="el-icon-plus" type="primary" @click="handlerAddStaff">员工</el-button>
                    </el-form-item>
                </el-form>
                <span class="a-c-error a-fs-14 block">1.添加客诉通知员工时，员工必须关注“毛豆充”公众号，否则将会添加失败！</span>
                <span class="a-c-error a-fs-14 block">2.若添加非商户员工为客诉通知人时，须点击“+员工”按钮去添加员工，添加完成后点击商户列表“操作”栏中“设置客诉通知人”按钮设置客诉通知人</span>
                <div class="a-flex-cfsc">
                    <el-image 
                        style="width: 160px;height: 160px"
                        src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/wcode@2x.png" >
                    </el-image>
                    <span class="a-fs-14 a-c-666 a-ptb-20">扫码关注公众号</span>
                </div>
                
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            return {
                dialogVisible: false,
                form: {
                    id: '',
                },
                rules: {
                    id: [{ required: true, message: '请选择一个员工', trigger: 'change' }]
                },
                companyId: '',
                staffList: []
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                if(val) {
                    this.remoteMethod('')
                    this.getcComplaintReminder()
                }
            }
        },
        methods:{
            remoteMethod (query) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getCompanyUser,
                    params: {
                        companyId: this.companyId,//所属商户
                        mobile: query,
                        pageNum: 1,
                        pageSize: 200
                    },
                })
                .then(res => {
                    if(res.result.code == 0){
                        this.staffList = res.result.data.list
                    }
                })
                .catch((error) => {
                    this.$message.error(error.result.message)
                });
            },
            getcComplaintReminder () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.complaintReminder,
                    params: {
                        companyId: this.companyId,//所属商户
                    },
                })
                .then(res => {
                    if(res.result.code == 0){
                        this.form.id = res.result.data.userInfoList.map(item=>{
                            return item.id
                        })
                    }
                })
                .catch((error) => {
                    this.$message.error(error.result.message)
                });
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            ...this.form,
                            companyId: this.companyId,
                            ids: this.form.id
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.addComplaintLiaison,
                                params: reqData,
                            })
                            .then(res => {
                                if(res.result.code == 0){
                                    this.dialogVisible = false
                                    this.$message.success('操作成功')
                                    let ids = this.staffList.filter(item=>{
                                        return this.form.id.indexOf(item.id) != -1
                                    })
                                    this.$emit('handlerSuccess',ids);
                                }
                            })
                            .catch((error) => {
                                this.$message.error(error.result.message)
                            });
                        }).catch(_=>{ })
                    }
                })
                
            },
            handlerAddStaff () {
                this.dialogVisible = false
                this.$router.push({
					path: '/staffManage/staff-add?companyId=' + this.companyId + '&isCreateComplaintUser=1'
				})
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>